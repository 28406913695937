import React from "react"
import { useDiaryItem } from "../../hooks/useDiaryItem"

const DiaryItem = ({
  activity_type,
  items,
  getActivities,
  activites,
  activity_is_pending,
  handleOpenDrawer,
}) => {
  const { renderLabel, renderContent } = useDiaryItem({
    getActivities,
    activites,
    activity_is_pending,
    handleOpenDrawer,
    activity_type,
    items,
  })
  return (
    <>
      {renderLabel(activity_type, items[activity_type])}
      {renderContent(activity_type, items[activity_type])}
      <div
        style={{
          height: "1px",
          background: "#F0F0F0",
          width: "103.1%",
          marginTop: "9px",
          marginLeft: "-20px",
        }}
      />
    </>
  )
}

export default DiaryItem
